import React, { useState } from 'react';
import './index.css';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Hero Section */}
      <section className="relative h-screen bg-cover bg-center" style={{ backgroundImage: "url('https://axenthost.com/img/games/minecraft/banner.jpg')" }}>
        {/* Navigation Bar */}
        <nav className="absolute top-0 left-0 w-full p-4 bg-gray-900 bg-opacity-75 z-20 flex justify-between items-center">
          {/* Logo */}
          <div className="text-2xl font-bold">
            <a href="/" className="text-white hover:text-green-500 transition">SoulCraft</a>
          </div>

          {/* Hamburger Icon (for mobile) */}
          <button onClick={() => setMenuOpen(!menuOpen)} className="md:hidden text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>

          {/* Menu Links */}
          <div className={`space-y-2 md:space-y-0 md:flex md:space-x-4 ${menuOpen ? 'block' : 'hidden'} absolute md:static top-16 left-0 w-full md:w-auto bg-gray-900 bg-opacity-75 md:bg-transparent z-10 md:z-auto`}>
            <a href="#discord" className="block w-full md:w-auto text-center py-2 px-4 bg-green-500 rounded-lg text-white hover:bg-green-700 transition">Discord</a>
            <a href="#store" className="block w-full md:w-auto text-center py-2 px-4 bg-green-500 rounded-lg text-white hover:bg-green-700 transition">Store</a>
            <a href="#downloads" className="block w-full md:w-auto text-center py-2 px-4 bg-green-500 rounded-lg text-white hover:bg-green-700 transition">Downloads</a>
            <a href="#suggestions" className="block w-full md:w-auto text-center py-2 px-4 bg-green-500 rounded-lg text-white hover:bg-green-700 transition">Suggestions</a>
            <a href="#contact" className="block w-full md:w-auto text-center py-2 px-4 bg-green-500 rounded-lg text-white hover:bg-green-700 transition">Contact</a>
          </div>
        </nav>

        {/* Dark overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-70 z-10"></div>
        {/* Blurred background */}
        <div className="absolute inset-0 filter blur-md z-10"></div>

        {/* Content */}
        <div className="relative h-full flex flex-col justify-center items-center z-10">
          <h1 className="text-5xl font-bold mb-4">Welcome to SoulCraft</h1>
          <p className="text-xl mb-6">Your ultimate Minecraft experience</p>
          <a href="minecraft://play.mystipixel.com" className="bg-green-500 px-6 py-3 rounded-lg text-lg hover:bg-green-700 transition">
            Play Now
          </a>
        </div>
      </section>

      {/* Flowbite Integration */}
      <section className="bg-gray-900">
        <div className="py-8 px-4 mx-auto space-y-12 max-w-screen-xl lg:space-y-20 sm:py-16 lg:px-6">
          {/* Row 1 */}
          <div className="gap-8 items-center lg:grid lg:grid-cols-2 xl:gap-16">
            <div className="text-gray-400 sm:text-lg">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">
                My Super Cool Feature 1
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Unlock items and gear while porogressing through our collections!
              </p>
              <ul
                role="list"
                className="pt-8 my-7 space-y-5 border-t border-gray-700"
              >
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-white">
                    Armors
                  </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-white">
                    Items
                  </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-white">
                    Enchants
                  </span>
                </li>
              </ul>
            </div>
            <img
              className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out"
              src="https://placehold.co/600x400"
              alt="Office feature image"
            />
          </div>

          {/* Row 2 */}
          <div className="gap-8 items-center lg:grid lg:grid-cols-2 xl:gap-16">
            <img
              className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out"
              src="https://placehold.co/600x400"
              alt="Office feature image 2"
            />
            <div className="text-gray-400 sm:text-lg">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">
              My Super Cool Feature 2
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Use minions to produce while your offline
              </p>
              <ul
                role="list"
                className="pt-8 my-7 space-y-5 border-t border-gray-700"
              >
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-white">
                    Cobblestone
                  </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-white">
                    Diamond
                  </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-white">
                    Emerald
                  </span>
                </li>
              </ul>
            </div>
          </div>

          {/* Row 3 */}
          <div className="gap-8 items-center lg:grid lg:grid-cols-2 xl:gap-16">
            <div className="text-gray-400 sm:text-lg">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">
              My Super Cool Feature 3
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Quests and more to keep you busy
              </p>
              <ul
                role="list"
                className="pt-8 my-7 space-y-5 border-t border-gray-700"
              >
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-white">
                    Unlcok more quests
                  </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-white">
                    items
                  </span>
                </li>
                <li className="flex space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-green-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-white">
                    talismans
                  </span>
                </li>
              </ul>
            </div>
            <img
              className="hidden mb-4 w-full lg:mb-0 lg:flex rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out"
              src="https://placehold.co/600x400"
              alt="Office feature image"
            />
          </div>
        </div>
      </section>

      {/* Staff Section with Avatars */}
      <section className="py-16 px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Meet the Staff</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div className="bg-gray-800 p-6 rounded-lg text-center">
            <img
              src="https://randomuser.me/api/portraits/men/32.jpg"
              alt="Admin - Alex"
              className="w-24 h-24 rounded-full mx-auto mb-4 border-4 border-gray-700"
            />
            <h3 className="text-xl font-semibold mb-2">Admin - Alex</h3>
            <p>Managing the community and ensuring server stability.</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg text-center">
            <img
              src="https://randomuser.me/api/portraits/women/44.jpg"
              alt="Moderator - Jamie"
              className="w-24 h-24 rounded-full mx-auto mb-4 border-4 border-gray-700"
            />
            <h3 className="text-xl font-semibold mb-2">Moderator - Jamie</h3>
            <p>Helping players and moderating the server.</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg text-center">
            <img
              src="https://randomuser.me/api/portraits/women/65.jpg"
              alt="Builder - Taylor"
              className="w-24 h-24 rounded-full mx-auto mb-4 border-4 border-gray-700"
            />
            <h3 className="text-xl font-semibold mb-2">Builder - Taylor</h3>
            <p>Creating amazing in-game builds and events.</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
